import styles from "./TopNotifications.module.css";

const TopNotifications = (props) => {
  const hideNotificationBar = () => {
    props.notificationStatus(false);
  };
  return (
    <div className={styles.topNotificationBar}>
      <div className="container">
        <div className={`${styles.notificationBox}`}>
        <div className={styles.notificationMsg}>
          🔔 SaleAssist.ai is a proud winner of HDFC Life Futurance program. View details 🎉🚀
            <a
              href="https://bfsi.economictimes.indiatimes.com/news/insurance/hdfc-life-riidl-somaiya-vidyavihar-join-hands-for-futurance-phase-6-demo-day/115045490"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            🎉🚀
          </div>
          <button
            className={styles.notificationsCloseBtn}
            onClick={hideNotificationBar}
          >
            <img src="/assets/images/icons/ic-close.svg" alt="close icon" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default TopNotifications;
