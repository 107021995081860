import React from "react"
import PathConstants from "./pathConstants"
const Home = React.lazy(() => import("../pages/Home"))
const PrivacyPolicyContent = React.lazy(() => import("../pages/PrivacyPolicy"))
const TermsConditions = React.lazy(() => import("../pages/TermsConditions"))
const FeaturePage = React.lazy(() => import("../pages/FeaturePage"))
const Pricing = React.lazy(() => import("../pages/Pricing"))
const Strike = React.lazy(() => import("../pages/Strike"))
const Partner = React.lazy(() => import("../pages/Partner"))
const AboutNew = React.lazy(() => import("../pages/AboutNew"))
const D2C = React.lazy(() => import("../pages/D2C"))
const DemoPage = React.lazy(() => import("../pages/DemoPage"))
const Testing = React.lazy(() => import("../pages/Testing"))

const routes = [
    { path: PathConstants.HOME, element: <Home /> },
    { path: PathConstants.PrivacyPolicy, element: <PrivacyPolicyContent /> },
    { path: PathConstants.TermsConditions, element: <TermsConditions /> },
    { path: PathConstants.FeaturePage, element: <FeaturePage /> },
    { path: PathConstants.Pricing, element: <Pricing /> },
    { path: PathConstants.Strike, element: <Strike /> },
    { path: PathConstants.Partner, element: <Partner /> },
    { path: PathConstants.AboutUs, element: <AboutNew /> },
    { path: PathConstants.Reels, element: <D2C /> },
    { path: PathConstants.DemoPage, element: <DemoPage /> },
    { path: PathConstants.Testing, element: <Testing /> },
];

export default routes